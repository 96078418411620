<template>
  <div class="highlight" :class="{'highlight__product': $route.name === 'HighlightProduct'}">
    <router-view />
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.highlight {
  flex-grow: 1;

  &__product {
    padding: 0 2.604vw;
  }
}
</style>
